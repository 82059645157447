import { Typography } from 'aim-components';
import { show } from '@/components/common/external/Intercom';
import { useEffect } from 'react';
import { useMobile } from 'aim-utils';
import { FSErrorPage } from '@/lib/fullstory';

const ServiceDownError = () => {
  const mobileView = useMobile();
  useEffect(() => {
    !mobileView && show();
  }, [mobileView]);

  useEffect(() => {
    FSErrorPage(window.location.href, 'ServiceDownError');
  }, []);

  return (
    <>
      <div>
        <Typography variant='text1'>
          Gilion is temporarily unavailable, but we are working hard to fix the problem. We will be up and running soon!
          <br />
          <br />
          If you need any help, reach out to us via the chat or email{' '}
          <a href='mailto:help@gilion.com'>help@gilion.com</a>.
        </Typography>
      </div>
    </>
  );
};

export default ServiceDownError;
