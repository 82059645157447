import type { OryFlow } from 'pages/login/index.page';
import type { EntryType } from './EntryWrapper';
import { TextInput } from 'aim-components';
import { oryNode } from 'aim-ory';
import { useTranslation } from 'next-i18next';
import type { Dispatch, SetStateAction } from 'react';
import { useContext } from 'react';
import { isValidEmail } from '@/lib/validation';
import { LoginContext } from './LoginContext';

interface EmailEntryFieldProps {
  flow: OryFlow;
  entryType: EntryType;
  onEnter: () => void;
  userInput: {
    email: string;
    password: string;
    name: string;
  };
  setUserInput: Dispatch<
    SetStateAction<{
      email: string;
      password: string;
      name: string;
    }>
  >;
}
export const EmailEntryField = ({ flow, entryType, userInput, setUserInput, onEnter }: EmailEntryFieldProps) => {
  const { t } = useTranslation(entryType);
  const nodeName = entryType === 'login' ? 'identifier' : 'traits.email';
  const flowEmailIdentifier = oryNode(flow.data.nodes[nodeName]);

  const loginContext = useContext(LoginContext);
  if (!loginContext) return null;
  const { emailError, setEmailError } = loginContext;

  return (
    <div>
      <TextInput
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (!isValidEmail(userInput.email)) {
              setEmailError(true);
            } else {
              onEnter();
            }
          }
        }}
        name={oryNode(flow.data.nodes[nodeName]).attributes.name}
        label={t('emailLabel')}
        placeholder={t('placeholder')}
        value={userInput.email}
        isValid={flowEmailIdentifier?.messages?.length === 0 && !emailError ? undefined : false}
        validationError={
          flowEmailIdentifier?.messages?.length === 0
            ? t('error.invalidEmail')
            : flowEmailIdentifier?.messages?.[0]?.text
        }
        onChange={(e) => {
          setUserInput({ ...userInput, email: e.target.value });
          if (isValidEmail(e.target.value)) {
            setEmailError(false);
          }
        }}
        onBlur={(e) => {
          if (!isValidEmail(e.target.value)) {
            setEmailError(true);
          }
        }}
      />
    </div>
  );
};
