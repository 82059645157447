import type { UtmData } from '@/api/registration/RegistrationData';

export function getCookie(cookieName: string): string {
  if (typeof window === 'undefined') {
    return '';
  }
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function expireCookie(cookieName: string): Promise<void> {
  document.cookie = cookieName + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
  return new Promise((resolve) => {
    setTimeout(() => resolve(), 100); //updating a cookie is sometimes a bit slow, which can lead to odd behavior
  });
}

function modifyUtmData(utmParam: string | null): string | null {
  if ((utmParam && utmParam.length === 0) || !utmParam) {
    return null;
  }
  return utmParam;
}

export function addUtmData(): UtmData {
  const campaignCookie = getCookie('__gtm_campaign_url');
  if (campaignCookie) {
    const url = new URL(campaignCookie);
    if (url.searchParams.get('utm_source')) {
      return {
        campaign: modifyUtmData(url.searchParams.get('utm_campaign')),
        medium: modifyUtmData(url.searchParams.get('utm_medium')),
        source: modifyUtmData(url.searchParams.get('utm_source')),
        content: modifyUtmData(url.searchParams.get('utm_content')),
      };
    }
  }
  const referrer = getCookie('__gtm_referrer');
  if (referrer) {
    return { source: modifyUtmData(referrer), medium: 'Organic' };
  }
  return { source: 'Direct', medium: 'Direct' };
}
