import { Typography } from 'aim-components';
import styles from './EntryWrapper.module.css';
import type { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import { Routes } from '@/lib/routes';
import { useRouter } from 'next/router';

export type EntryType = 'login' | 'signUp';

interface EntryWrapperProps {
  title: string;
  subtitle?: string;
  entryType?: EntryType;
  children: ReactNode;
}

export const EntryWrapper = ({ title, subtitle, entryType, children }: EntryWrapperProps) => {
  const { t } = useTranslation(entryType);
  const router = useRouter();

  return (
    <div className={styles.entryWrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Typography variant='title2'>{title}</Typography>
          {subtitle && (
            <Typography variant='text1' color='secondary' className={styles.subtitle}>
              {subtitle}
            </Typography>
          )}
        </div>

        {children}
      </div>
      {entryType === 'login' && (
        <Typography variant='text1' color='secondary' className={styles.text}>
          {t('noAccount')}
          <span onClick={() => router.push(Routes.SignUp)} className={styles.link}>
            {t('signUp')}
          </span>
        </Typography>
      )}
      {entryType === 'signUp' && (
        <Typography variant='text1' color='secondary' className={styles.text}>
          {t('hasAccount')}
          <span onClick={() => router.push(Routes.Login)} className={styles.link}>
            {t('login')}
          </span>
        </Typography>
      )}
    </div>
  );
};
