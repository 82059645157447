import { useEffect, useState } from 'react';
import { LoginContext } from './LoginContext';
import Image from 'next/image';
import logo from '../../public/svg/gilion-black-no-trademark.svg';
import styles from './SignupAndLoginLayout.module.css';
import { useMobile } from 'aim-utils';

export const USER_IS_LOOKING_FOR = 'userLookingFor';

type ImageName = 'flowers-right' | 'flowers-left' | 'analytics' | 'investors';

const getImage = (image: ImageName | undefined) => {
  switch (image) {
    case 'flowers-right':
      return '/images/signup/flowers-right.png';
    case 'flowers-left':
      return '/images/signup/flowers-left.png';
    case 'analytics':
      return '/images/signup/analytics.png';
    case 'investors':
      return '/images/signup/investors.webp';
    default:
      return '/images/signup/flowers-left.png';
  }
};

interface SignupAndLoginLayoutProps {
  children?: React.ReactNode;
  imageOverlay?: React.ReactNode;
  image?: ImageName;
  displayImageOnMobile?: boolean;
}

export const SignupAndLoginLayout = ({
  children,
  imageOverlay,
  image,
  displayImageOnMobile,
}: SignupAndLoginLayoutProps) => {
  const [userInput, setUserInput] = useState({ email: '', password: '', name: '' });
  const [emailError, setEmailError] = useState(false);

  const { mobileView } = useMobile();

  const [clientRendering, setClientRendering] = useState(false);
  useEffect(() => setClientRendering(true), []); // Client rendering is need for conditional mobileView components

  return (
    <LoginContext.Provider
      value={{
        userInput: userInput,
        setUserInput: setUserInput,
        emailError: emailError,
        setEmailError: setEmailError,
      }}
    >
      <div className={styles.gilionBanner}>
        <Image src={logo} alt='Gilion' height={18} />
      </div>
      <div className={styles.heroBlockContainer}>
        {((!mobileView && clientRendering) || displayImageOnMobile) && (
          <div className={styles.heroBlock}>
            <div className={styles.image}>
              <Image src={getImage(image)} alt='Gilion illustration' fill objectFit='cover' quality={100} />
            </div>
            <div className={styles.imageOverlay}>{imageOverlay}</div>
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </LoginContext.Provider>
  );
};
