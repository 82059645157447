import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

export enum LookingFor {
  Analytics = 'analytics',
  Funding = 'funding',
  Investors = 'investors',
}

export const LoginContext = createContext<{
  userInput: {
    email: string;
    password: string;
    name: string;
  };
  setUserInput: Dispatch<
    SetStateAction<{
      email: string;
      password: string;
      name: string;
    }>
  >;
  emailError: boolean;
  setEmailError: Dispatch<SetStateAction<boolean>>;
} | null>(null);
