import { Button, Typography } from 'aim-components';
import styles from './EntryForm.module.css';
import type { OryFlow } from 'pages/login/index.page';
import { EntryInputFields } from './EntryInputFields';
import { oryNode } from 'aim-ory';
import { Trans, useTranslation } from 'next-i18next';
import { useState, type FormEvent } from 'react';
import { EntryWrapper, type EntryType } from './EntryWrapper';
import { PRIVACY_NOTICE_LINK, TERMS_OF_SERVICE_LINK } from '@/lib/legalDocuments/legalDocuments';

interface EntryFormProps {
  flow: OryFlow;
  onSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  toggleAuthenticateMethod: () => void;
  entryType: EntryType;
}

export const EntryForm = ({ flow, onSubmit, toggleAuthenticateMethod, entryType }: EntryFormProps) => {
  const { t } = useTranslation(entryType);
  const [loading, setLoading] = useState(false);
  const [filledOutForm, setFilledOutForm] = useState(false);

  const forgotPassword = () =>
    (window.location.href = `${process.env.NEXT_PUBLIC_ORY_URL}/self-service/recovery/browser`);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    await onSubmit(event);
    setLoading(false);
  };

  return (
    <EntryWrapper title={t('titlePassword')} entryType={entryType}>
      <form
        className={styles.entryEmailForm}
        action={flow.data.action}
        method={flow.data.method}
        onSubmit={handleSubmit}
      >
        <div className={styles.toSSOPageButton}>
          <Button
            type='secondary'
            color='white'
            size='medium'
            onClick={toggleAuthenticateMethod}
            startIcon={'arrow-left'}
          >
            {t('entryWithSSO')}
          </Button>
        </div>
        <div>
          <EntryInputFields flow={flow} entryType={entryType} setFilledOutForm={setFilledOutForm} />
          <div className={styles.entryButton}>
            <Button
              name={oryNode(flow.data.nodes.method).attributes.name}
              value={oryNode(flow.data.nodes.method).attributes.value}
              buttonType='submit'
              size='medium'
              loading={loading}
              disabled={!filledOutForm}
            >
              {t('entryButton')}
            </Button>
            {entryType !== 'signUp' && (
              <div style={{ width: 'fit-content' }}>
                <Typography variant='text1' color='secondary' className={styles.link} onClick={forgotPassword}>
                  {t('forgotPassword')}
                </Typography>
              </div>
            )}
            {entryType === 'signUp' && (
              <Typography variant='text1' color='secondary' className={styles.termOfService}>
                <Trans t={t} i18nKey='agreement'>
                  <a href={TERMS_OF_SERVICE_LINK} target='_blank' rel='noopener noreferrer' />
                  <a href={PRIVACY_NOTICE_LINK} target='_blank' rel='noopener noreferrer' />
                </Trans>
              </Typography>
            )}
          </div>
        </div>
      </form>
    </EntryWrapper>
  );
};
