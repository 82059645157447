import { Button, Spinner, TextInput, Typography } from 'aim-components';
import styles from './SSOEntry.module.css';
import { oryNode } from 'aim-ory';
import type { OryFlow } from 'pages/login/index.page';
import { useTranslation } from 'next-i18next';
import { EntryWrapper, type EntryType } from './EntryWrapper';
import { useCallback, useContext, useState } from 'react';
import { EmailEntryField } from './EmailEntryField';
import { LoginContext, LookingFor } from './LoginContext';
import { getCookie } from 'pages/registration/utils';
import { USER_IS_LOOKING_FOR } from './SignupAndLoginLayout';

interface SSOEntryProps {
  flow: OryFlow;
  toggleAuthenticateMethod: () => void;
  entryType: EntryType;
}

/** Used to provide feedback to the user while the SSO window is loading, and to prevent consecutive form submissions if the user were to double-click or similar */
const DURATION_TO_DISABLE_BUTTON_CLICKS_AFTER_FORM_SUBMISSION_MS = 10_000;

export const SSOEntry = ({ flow, toggleAuthenticateMethod, entryType }: SSOEntryProps) => {
  const { t } = useTranslation(entryType);
  const { t: tLogin } = useTranslation('login');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loginContext = useContext(LoginContext);

  const handleOnSubmit = useCallback(() => {
    setIsSubmitting(true);
    setTimeout(() => setIsSubmitting(false), DURATION_TO_DISABLE_BUTTON_CLICKS_AFTER_FORM_SUBMISSION_MS);
  }, []);
  if (!loginContext) return null;
  const { userInput, setUserInput, emailError } = loginContext;

  const userIsLookingFor = getCookie(USER_IS_LOOKING_FOR);

  const title = (() => {
    switch (userIsLookingFor) {
      case LookingFor.Funding:
        return tLogin('titleFunding');
      case LookingFor.Investors:
        return tLogin('titleInvestors');
      default:
        return tLogin('title');
    }
  })();

  const subtitle = (() => {
    switch (userIsLookingFor) {
      case LookingFor.Funding:
        return tLogin('subtitleFunding');
      case LookingFor.Investors:
        return tLogin('subtitleInvestors');
      default:
        return tLogin('subtitle');
    }
  })();

  return (
    <EntryWrapper title={title} subtitle={subtitle} entryType={entryType}>
      {isSubmitting && (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}

      {/* We hide the buttons visually (with CSS) to avoid submitting the form while it's already submitting. Making updates to the form or the buttons (like setting a loading state) while the form is submitting does seem to cancel the form action. */}
      <div className={styles.ssoContainer} style={{ display: isSubmitting ? 'none' : 'flex' }}>
        {flow.data.nodes['provider-google'] && (
          <form
            className={styles.ssoButton}
            action={flow.data.action}
            method={flow.data.method}
            onSubmit={handleOnSubmit}
          >
            <TextInput name='csrf_token' value={oryNode(flow.data.nodes.csrf_token).attributes.value} type='hidden' />
            <Button
              buttonType='submit'
              type='primary'
              color='dark'
              size='medium'
              startIcon={'google-color'}
              name='provider'
              value={oryNode(flow.data.nodes['provider-google']).attributes.value}
              className={styles.leftAlignedButtons}
            >
              {t('entryGoogle')}
            </Button>
          </form>
        )}
        {flow.data.nodes['provider-microsoft'] && (
          <form
            className={styles.ssoButton}
            action={flow.data.action}
            method={flow.data.method}
            onSubmit={handleOnSubmit}
          >
            <TextInput name='csrf_token' value={oryNode(flow.data.nodes.csrf_token).attributes.value} type='hidden' />
            <Button
              buttonType='submit'
              type='primary'
              color='dark'
              size='medium'
              startIcon={'microsoft-color'}
              name='provider'
              value={oryNode(flow.data.nodes['provider-microsoft']).attributes.value}
              className={styles.leftAlignedButtons}
            >
              {t('entryMicrosoft')}
            </Button>
          </form>
        )}
      </div>

      <div className={styles.divider}>
        <div className={styles.dividerLine} />
        <Typography variant='text2' color='secondary'>
          {tLogin('or')}
        </Typography>
        <div className={styles.dividerLine} />
      </div>

      <div className={styles.emailForm}>
        <EmailEntryField
          flow={flow}
          entryType={entryType}
          userInput={userInput}
          setUserInput={setUserInput}
          onEnter={toggleAuthenticateMethod}
        />

        <Button
          type='primary'
          color='white'
          onClick={toggleAuthenticateMethod}
          disabled={userInput.email === '' || emailError}
        >
          <Typography variant='text1'>{t('entryEmail')}</Typography>
        </Button>
      </div>
    </EntryWrapper>
  );
};
