import { TextInput } from 'aim-components';
import styles from './EntryInputFields.module.css';
import { oryNode } from 'aim-ory';
import { useTranslation } from 'next-i18next';
import type { OryFlow } from 'pages/login/index.page';
import type { EntryType } from './EntryWrapper';
import { useContext, useEffect } from 'react';
import { isValidEmail } from '@/lib/validation';
import { LoginContext } from './LoginContext';

interface EntryInputFieldsProps {
  flow: OryFlow;
  entryType: EntryType;
  setFilledOutForm: (filledOutForm: boolean) => void;
}

export const EntryInputFields = ({ flow, entryType, setFilledOutForm }: EntryInputFieldsProps) => {
  const { t } = useTranslation(entryType);
  const nodeName = entryType === 'login' ? 'identifier' : 'traits.email';
  const flowEmailIdentifier = oryNode(flow.data.nodes[nodeName]);

  const loginContext = useContext(LoginContext);

  useEffect(() => {
    if (!loginContext) return;
    const { email, password, name } = loginContext.userInput;
    if (entryType === 'signUp') {
      setFilledOutForm(email !== '' && !loginContext.emailError && password !== '' && name !== '');
    } else {
      setFilledOutForm(email !== '' && !loginContext.emailError && password !== '');
    }
  }, [entryType, loginContext, setFilledOutForm]);

  if (!loginContext) return null;

  const { userInput, emailError, setUserInput, setEmailError } = loginContext ?? {};

  return (
    <div className={styles.inputFields}>
      <TextInput name='csrf_token' value={oryNode(flow.data.nodes.csrf_token).attributes.value} type='hidden' />

      <TextInput
        name={oryNode(flow.data.nodes[nodeName]).attributes.name}
        label={t('emailLabel')}
        value={userInput.email}
        placeholder={t('placeholder')}
        isValid={flowEmailIdentifier?.messages?.length === 0 && !emailError ? undefined : false}
        validationError={
          flowEmailIdentifier?.messages?.length === 0
            ? t('error.invalidEmail')
            : flowEmailIdentifier?.messages?.[0]?.text
        }
        onChange={(e) => {
          setUserInput({ ...userInput, email: e.target.value });
          if (isValidEmail(e.target.value)) {
            setEmailError(false);
          }
        }}
        onBlur={(e) => {
          if (!isValidEmail(e.target.value)) {
            setEmailError(true);
          }
        }}
      />

      {entryType === 'signUp' && (
        <TextInput
          name={oryNode(flow.data.nodes['traits.displayName']).attributes.name}
          label={t('nameLabel')}
          placeholder={t('placeholder')}
          isValid={oryNode(flow.data.nodes['traits.displayName'])?.messages?.length === 0 ? undefined : false}
          validationError={
            oryNode(flow.data.nodes['traits.displayName'])?.messages?.length > 0
              ? oryNode(flow.data.nodes['traits.displayName'])?.messages?.[0]?.text
              : undefined
          }
          onChange={(e) => setUserInput({ ...userInput, name: e.target.value })}
        />
      )}

      <TextInput
        name={oryNode(flow.data.nodes.password).attributes.name}
        label={t('passwordLabel')}
        placeholder={t('typeHere')}
        type='password'
        isValid={oryNode(flow.data.nodes.password).messages.length === 0 ? undefined : false}
        validationError={
          oryNode(flow.data.nodes.password).messages.length > 0
            ? oryNode(flow.data.nodes.password).messages[0].text
            : undefined
        }
        onChange={(e) => {
          setUserInput({ ...userInput, password: e.target.value });
        }}
      />
    </div>
  );
};
